<template>
	<div>
		<el-form :model="formData" :rules="rules" ref="ruleForm" status-icon label-width="100px"  v-loading="loading">
			<el-form-item label="姓名" prop="name">
				<el-input type="text" placeholder="请输入姓名" v-model="formData.name" autocomplete="off"></el-input>
			</el-form-item>
            <el-form-item label="提现类型" prop="status">
                <el-select v-model="formData.status" placeholder="提现类型">
                	<el-option label="支付宝" :value="1"></el-option>
                	<el-option label="银行卡" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="提现账号" prop="account">
				<el-input type="text" placeholder="请输入提现账号" v-model="formData.account" autocomplete="off"></el-input>
			</el-form-item>

		</el-form>
		<div class="drawer-footer">
			<Button type="primary" @click="onSubmit('ruleForm')">提交</Button>
		</div>
	</div>
</template>
<script>
import {request} from "@/library/request.js";
export default {
	
	data () {
		return {
			formData:{
				name:"",
				status:"",
				account:"",
			},
			id:"",
			loading:false,
			rules:{
				name:[
					{required: true, message: '请输入姓名', trigger: 'blur' },
					{max:40, message: '长度在50个字符', trigger: 'blur' }
				],
				status:[
					{required: true, message: '请选择提现类型', trigger: 'change' }
				],
				account:[
					{required: true, message: '请输入提现账号', trigger: 'change'}
				],
				
			}
		}
	},
	methods:{
		
		formDataPid(value) {
			if(value.length>0){
				this.formData.pid=value[0]
			}else{
				this.formData.pid=0
			}
		},
		onSubmit(formName){
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.save()
				} else {
					return false;
				}
			});
		},
		save(){
			var that = this
			this.loading = true
			return request({
				url:'withdrawal/edit/'+this.id,
				method:"post",
				params:this.formData
			}).then(response  => {
				this.loading = false
				if(response.code==200){
					this.$Message.success({
						content:response.msg,
						duration:1.5,
						onClose:function(){	
							that.$emit('routerCancel', true)
						}
					})
				}else{
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
				}
			}).then(err => {
				if(err){
					this.loading = false
					this.$Message.error("系统异常，请稍后重试")
				}
			})
		},
		detail(id){
			this.loading = true
			request({
				url:'withdrawal/edit/'+id,
				method:"get",
			}).then(response  => {
				this.loading = false
				if(response.code!=200){
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
					
				}else{
					this.formData = response.data
				}
				
			}).then(err => {
				if(err){
					this.loading = false
					this.$Message.error("系统异常，请稍后重试")
				}
			})
		}
	},
	watch:{
        '$route.params': {
			handler(newVal,oldVal){
				this.id = newVal.id
				this.detail(newVal.id)
			},
            immediate: true
        }
    }


}
</script>
<style scoped>
	.drawer-footer{
		width: 100%;
		bottom: 0;
		left: 0;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}
</style>